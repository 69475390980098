<template>
  <div class="body">
    <article class="banner">
      <div>
        <h2><strong>橙仕01</strong>配送小哥的车</h2>
        <p class="slogan">装的多<span>｜</span>送得快<span>｜</span>保障好<span>｜</span>用的省</p>
        <span class="btnBook" @click="showPopup">预约抢购</span>
      </div>

      <picture style="width: 100%">
        <source
          srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/banner.jpg 1x"
          media="all and (max-width: 500px)"
          type="image/jpg"
        />
        <img
          class="ani"
          src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/banner.jpg"
          width="1920"
          height="640"
        />
      </picture>
    </article>

    <!-- <header class="subHeader">
      <div class="subHeaderWrap">
        <h2><img src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/logo.png" width="120" height="34" /></h2>
        <ul class="subNav">
          <li class="current"><a href="#">概览</a></li>
          <li><a href="#">画廊</a></li>
          <li><a href="#">参数</a></li>
          <li><span class="btnBook J_trigger">我要预定</span></li>
        </ul>
      </div>
    </header> -->

    <section class="layout01">
      <div class="row">
        <div class="box strongBox">
          <div class="summary">
            <h3>他很<strong>硬朗</strong></h3>
            <p>就像台小吉普</p>
          </div>
          <picture style="width: 100%">
            <source
              srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/26/strong.png 1x"
              media="all and (max-width: 500px)"
              type="image/png"
            />
            <img
              class="ani"
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/strong.png"
              width="960"
              height="505"
            />
          </picture>
        </div>
        <div class="box fashionBox">
          <div class="summary">
            <h3>他很<strong>简洁</strong></h3>
            <p>一种高级的时尚</p>
          </div>
          <picture style="width: 100%">
            <source
              srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/26/fashion.png 1x"
              media="all and (max-width: 500px)"
              type="image/png"
            />
            <img
              class="ani"
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/fashion.png"
              width="960"
              height="505"
            />
          </picture>
        </div>
      </div>

      <div class="box smallBox">
        <div class="summary ani">
          <h3>他很<strong>小</strong></h3>
          <h4>轻松通过窄街小巷</h4>
          <p>车头窄，轴距短，转弯半径小</p>
          <p>复杂狭小空间通行毫无压力</p>
        </div>
        <picture>
          <source
            srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/small.jpg 1x"
            media="all and (max-width: 500px)"
            type="image/jpg"
          />
          <img
            src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/small.jpg"
            width="1920"
            height="1080"
          />
        </picture>
      </div>

      <div class="box bigBox">
        <div class="summary">
          <h3>他很<strong>大</strong></h3>
          <h4>巨能装，您的移动的小仓库</h4>
          <p>极致的空间设计，合理配置空间</p>
        </div>
        <picture style="width: 100%">
          <source
            srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/26/big.png 1x"
            media="all and (max-width: 500px)"
            type="image/png"
          />
          <img
            class="ani"
            src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/big.png"
            width="950"
            height="600"
          />
        </picture>
      </div>

      <div class="row spaceRow" style="background-color: #e3eae5">
        <div class="box spaceBox">
          <div class="summary">
            <h3>
              <strong>可扩展空间<span class="mHide">，</span></strong
              >装的更多
            </h3>
            <p>可拆卸副驾，多用仓，<br class="mShow" />多达2.74m³货厢容积</p>
            <p>让你少送一次，多赚一点</p>
          </div>
          <picture style="width: 100%">
            <source
              srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/26/space.png 1x"
              media="all and (max-width: 500px)"
              type="image/png"
            />
            <img
              class="ani"
              src="https://alihtml.juzhen.cn/juzhen/assets/img/mycar/space.png"
              width="790"
              height="360"
            />
          </picture>
        </div>
        <div class="box loadingBox">
          <div class="summary">
            <h3><strong>装卸更从容</strong></h3>
            <p>可掀底板，增加25cm深</p>
            <p>方便装卸货</p>
          </div>
          <picture style="width: 100%">
            <source
              srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/26/loading.png 1x"
              media="all and (max-width: 500px)"
              type="image/png"
            />
            <img
              class="ani"
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/loading.png"
              width="790"
              height="360"
            />
          </picture>
        </div>
      </div>

      <div class="safeBox">
        <div class="safeWrap">
          <div class="safe_1">
            <div class="summary">
              <h2>他很<strong>安全</strong></h2>
              <h3>高技术研发,重视试验验证,<strong>确保安全</strong></h3>
              <p>
                整车布置、造型设计、车身、内外饰、整车控制器、底盘匹配、动力电池、VCU开发及CAE分析等620余项设计规范符合国家和行业标准。同时整车性能开发经过了多轮多种试验验证，以确保整车产品安全可靠。零部件更是验证充分，装车前经过了台架性能试验、盐雾试验、高低温试验、振动试验等
              </p>
            </div>
            <div class="picWrap ani">
              <img
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/03/30/safe_1.png"
                width="1023"
                height="520"
              />
            </div>
          </div>
          <div class="safeOther">
            <div class="safe_2">
              <div class="summary">
                <h3><strong>保障人身安全</strong></h3>
                <p>乘用级的<strong>安全车身，</strong>经过上万次的测试</p>
                <img
                  class="icon"
                  src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/icon_safe_1.png"
                  width="78"
                  height="93"
                />
              </div>
              <picture style="width: 100%">
                <source
                  srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/safe_1.jpg 1x"
                  media="all and (max-width: 500px)"
                  type="image/jpg"
                />
                <img
                  class="ani"
                  src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/03/30/safe_2.png"
                  width="579"
                  height="251"
                />
              </picture>
            </div>
            <div class="safe_3">
              <div class="summary">
                <h3>核心零部件专项<strong>安全性能提升</strong></h3>
                <p>
                  对新能源关键零部件动力电池包重点关注，重点突破，确保在使用过程和日常存中放零事故。基于市场上出现的电动汽车泡水、碰撞、底盘划伤后的起火事件，电池系统安全从系统设计（机械安全、热安全、电气安全）、安全测试、生产三阶段展开，保证电池系统的安全。
                </p>
                <img
                  class="icon"
                  src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/icon_safe_3.png"
                />
              </div>
            </div>
            <div class="safe_4">
              <div class="summary">
                <h3><strong>独立悬架</strong>底盘</h3>
                <img
                  class="icon"
                  src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/03/30/icon_chassis.png"
                  width="24"
                  height="34"
                />
                <p>结构紧凑，操控响应速度快</p>
                <p>只为给你带来舒适的驾驶体验</p>
              </div>
              <div class="picWrap ani">
                <picture style="width: 100%">
                  <source
                    srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/03/30/safe_3_m.png 1x"
                    media="all and (max-width: 500px)"
                    type="image/jpg"
                  />
                  <img
                    src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/03/30/safe_3.png"
                    width="994"
                    height="423"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box batteryBox">
        <div class="summary">
          <h3><strong>续航</strong>不焦虑</h3>
          <p>适合你的<strong>续航能力</strong>，才是你需要的</p>
        </div>

        <div class="row batteryRow">
          <div class="item">
            <video
              v-show="initPlay"
              ref="video"
              class="video"
              muted
              webkit-playsinline
              playsinline
              x5-playsinline
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              loop
              preload="preload"
              name="media"
              width="750"
              height="504"
              poster="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/03/30/poster.jpg"
            >
              <source
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/04/14/battery.mp4"
                type="video/mp4"
              />
            </video>
            <img
              v-show="!initPlay"
              ref="poster"
              class="poster"
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/03/30/poster.jpg"
              width="750"
              height="504"
              @click="videoPlay"
            />
            <div class="txt">
              <img
                class="icon"
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/icon_battery.png"
                width="104"
                height="36"
              />
              <p>独创神器电池加油包</p>
              <p>双倍续航，随心切换，懂你所想</p>
            </div>
          </div>
          <div class="item">
            <img
              class="video"
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/03/30/battery2.jpg"
              width="750"
              height="504"
            />
            <div class="txt">
              <img
                class="icon icon2"
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/icon_battery_manage.png"
                width="50"
                height="43"
              />
              <p>
                搭配智能电池管理系统<span class="mHide">，</span><span class="mBlock">全方位保护电池</span>
              </p>
              <p>
                减少电池衰减<span class="mHide">，</span><span class="mBlock">电池更耐用，充电更安全</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="box panelBox">
        <div class="summary">
          <h3>
            <span class="mBlock">生意好帮手</span><span class="mHide">，</span>打造个人私域的<strong>信息板</strong>
          </h3>
          <p>
            自由创作<span>｜</span>展示个性<span>｜</span>随写随换<span>｜</span>反复使用
          </p>
          <p class="p1">
            <img
              class="icon"
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/icon_panel.png"
              width="101"
              height="68"
            />
          </p>
        </div>

        <picture style="width: 100%">
          <source
            srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/26/panel.png 1x"
            media="all and (max-width: 500px)"
            type="image/png"
          />
          <img
            class="ani"
            src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/panel.png"
            width="1152"
            height="625"
          />
        </picture>
      </div>

      <div class="detailBox">
        <picture style="width: 100%">
          <source
            srcset="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/design_banner.jpg 1x"
            media="all and (max-width: 500px)"
            type="image/png"
          />
          <img
            class="designBanner"
            src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/03/30/design_banner.jpg"
            width="1920"
            height="1197"
          />
        </picture>

        <div class="summary">
          <h3>每一处<strong>细节的设计</strong></h3>
          <p>希望给你带来一些小惊喜</p>
        </div>

        <ul class="list">
          <li class="ani">
            <img
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/details_1.jpg"
              width="510"
              height="303"
            />
            <div class="con">
              <h4>是汽车也是手机大屏幕</h4>
              <p>手机互联投屏</p>
            </div>
          </li>
          <li class="ani">
            <img
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/details_2.jpg"
              width="510"
              height="303"
            />
            <div class="con">
              <h4>适用多款手机置物盒</h4>
              <p>充电收纳完美契合</p>
            </div>
          </li>
          <li class="ani">
            <img
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/details_3.jpg"
              width="510"
              height="303"
            />
            <div class="con">
              <h4>水杯加温制冷自由切换</h4>
              <p>冬天给你一杯热水，温暖外出送件的你</p>
              <p>天热时，一杯冷饮，让你瞬间活力满满</p>
            </div>
          </li>
        </ul>
      </div>

      <div class="qualificationBox">
        <h3>资质齐全 <strong>轻松上牌</strong></h3>
        <p>资质齐全的车才是安全的</p>
        <p>不是任何企业都有资格造车</p>

        <ul class="list">
          <li class="ani">
            <img
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/icon_q_1.png"
              width="90"
              height="80"
            />
            <p>政府颁发<strong>整车制造资质</strong></p>
          </li>
          <li class="ani">
            <img
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/icon_q_2.png"
              width="70"
              height="80"
            />
            <p>济南快递协会<strong>理事单位</strong></p>
          </li>
          <li class="ani">
            <img
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/icon_q_3.png"
              width="70"
              height="80"
            />
            <p>济南市、山东省<strong>重点项目</strong></p>
          </li>
          <li class="ani">
            <img
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/icon_q_4.png"
              width="70"
              height="80"
            />
            <p>山东新旧动能<strong>转换重大项目</strong></p>
          </li>
        </ul>
      </div>

      <div class="paramsBox">
        <h3 class="title">
          <img
            class="paramsLogo"
            src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/logo_w.png"
            width="214"
            height="70"
          />
          <span>参数</span>
        </h3>

        <div class="con">
          <div class="summary">
            <img
              class="car"
              src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/10/params.png"
              width="974"
              height="320"
            />
          </div>
          <div class="params">
            <table>
              <thead>
                <tr>
                  <th class="tit">项目</th>
                  <th>轻享版</th>
                  <th>舒享版</th>
                  <th>尊享版</th>
                  <th>财富版</th>
                </tr>
              </thead>
              <tbody>
                <tr class="first">
                  <td class="tit">综合工况续航里程（km）</td>
                  <td>120</td>
                  <td>120</td>
                  <td>120</td>
                  <td>200</td>
                </tr>
                <tr>
                  <td class="tit">动力电池总电量（kWh）</td>
                  <td>10.36</td>
                  <td>10.36</td>
                  <td>10.36</td>
                  <td>20.72</td>
                </tr>
                <tr>
                  <td class="tit">充电功率（kWh）</td>
                  <td>1.5</td>
                  <td>3.3</td>
                  <td>3.3</td>
                  <td>3.3</td>
                </tr>
                <tr>
                  <td class="tit">充电方式</td>
                  <td>家用220v</td>
                  <td>家用220v</td>
                  <td>家用220v</td>
                  <td>家用220v</td>
                </tr>
                <tr class="last">
                  <td class="tit">充电时长（h）</td>
                  <td>7.5</td>
                  <td>4</td>
                  <td>4</td>
                  <td>8</td>
                </tr>
              </tbody>
              <tbody>
                <tr class="first">
                  <td class="tit">电动转向助力</td>
                  <td>—</td>
                  <td>—</td>
                  <td>●</td>
                  <td>○</td>
                </tr>
                <tr>
                  <td class="tit">最高时速（km/h）</td>
                  <td>71</td>
                  <td>71</td>
                  <td>71</td>
                  <td>71</td>
                </tr>
                <tr>
                  <td class="tit">BMS电池管理系统</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                </tr>
                <tr>
                  <td class="tit">冷暖空调</td>
                  <td>—</td>
                  <td>—</td>
                  <td>—</td>
                  <td>○</td>
                </tr>
                <tr>
                  <td class="tit">倒车影像</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                </tr>
                <tr>
                  <td class="tit">手机投屏</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                </tr>
                <tr>
                  <td class="tit">BMS电池管理系统</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                </tr>
                <tr>
                  <td class="tit">ABS+上坡辅助</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                </tr>
                <tr>
                  <td class="tit">前/后防撞梁</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                </tr>
                <tr>
                  <td class="tit">核定人员（人）</td>
                  <td>1/2</td>
                  <td>1/2</td>
                  <td>1/2</td>
                  <td>1/2</td>
                </tr>
                <tr>
                  <td class="tit">最大总质量（kg）</td>
                  <td>1450</td>
                  <td>1450</td>
                  <td>1450</td>
                  <td>1450</td>
                </tr>
                <tr>
                  <td class="tit">额定载货质量（kg）</td>
                  <td>570/495</td>
                  <td>570/495</td>
                  <td>570/495</td>
                  <td>475/410</td>
                </tr>
                <tr class="last">
                  <td class="tit">货厢容积（m³）</td>
                  <td>2.6/2.3</td>
                  <td>2.6/2.3</td>
                  <td>2.6/2.3</td>
                  <td>2.6/2.3</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="5">
                    <ul class="overview">
                      <li class="item">
                        <img
                          class="iconElectricity"
                          src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/icon_electricity.png"
                          width="55"
                          height="40"
                        />
                        <div class="con">
                          <p>动力电池总电量</p>
                          <p class="num"><strong>10.36</strong>kwh</p>
                        </div>
                      </li>
                      <li class="gap"></li>
                      <li class="item">
                        <img
                          class="iconMileage"
                          src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/icon_mileage.png"
                          width="62"
                          height="43"
                        />
                        <div class="con">
                          <p>综合工况续航里程</p>
                          <p class="num"><strong>120</strong>km</p>
                        </div>
                      </li>
                      <view class="gap"></view>
                      <li class="item">
                        <img
                          class="iconCharging"
                          src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/icon_charging.png"
                          width="52"
                          height="43"
                        />
                        <div class="con">
                          <p>家用电可充</p>
                          <p class="num"><strong>220</strong>v</p>
                        </div>
                      </li>
                    </ul>
                    <p class="tip">“—”表示无此配置，“●”表示有此配置，“○”表示可选装此配置</p>
                    <p class="tip">该参数说明根据当前车辆配置状态进行编制。为更好满足客户需求，橙仕01不断对汽车进行研发和改进，市场销售车型对部分配置及规格可能与本参数表有所不同。具体细节请咨询橙仕01官方销售或者授权经销商了解详情。</p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div class="bannerEnd">
        <img
          class="mHide"
          src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/banner_end.jpg"
          width="1920"
          height="840"
        />
        <div class="summary">
          <h3><strong>橙仕</strong>新能源</h3>
          <p>全新的快递配送工作体验</p>
          <span class="btnBook" @click="showPopup">预约抢购</span>
        </div>
      </div>
    </section>

    <transition name="modal">
      <div class="popup" v-if="popupShow">
        <div class="popupMasker"></div>
        <div class="popupContent">
          <div class="bookView" v-show="!submited">
            <span class="close" @click="hidePopup"></span>
            <div class="tips">
              <h3 class="title">订购说明</h3>
              <p>请如实填写申请资料，我们的销售人员会及时和你联系订购信息</p>
            </div>

            <form @submit="submitPopup">
              <ul class="formList">
                <li class="item">
                  <label for="name">姓名</label>
                  <div class="input">
                    <input
                      id="name"
                      v-model.trim="formData.name"
                      name="name"
                      type="text"
                      maxlength="20"
                      placeholder="请输入你的姓名"
                    />
                    <p class="error" v-show="formDataCheck.name">请输入你的姓名</p>
                  </div>
                </li>
                <li class="item">
                  <label for="mobile">手机号</label>
                  <div class="input">
                    <input
                      id="mobile"
                      v-model.trim="formData.mobile"
                      name="mobile"
                      type="text"
                      maxlength="20"
                      placeholder="请输入你的手机号"
                    />
                    <p class="error" v-show="formDataCheck.mobile">请输入你的手机号</p>
                  </div>
                </li>
                <li class="item" style="border-bottom: none">
                  <label for="area">所在城市</label>
                  <div class="input">
                    <input
                      id="area"
                      v-model.trim="formData.area"
                      name="area"
                      type="text"
                      maxlength="20"
                      placeholder="请输入所在城市"
                    />
                    <p class="error" v-show="formDataCheck.area">请输入所在城市</p>
                  </div>
                </li>
              </ul>
              <ul class="formList">
                <li class="item itemShow">
                  <label for="remark">备注</label>
                  <textarea
                    id="remark"
                    v-model.trim="formData.remark"
                    name="remark"
                    placeholder="请输入需要备注的信息"
                  ></textarea>
                </li>
              </ul>
              <div class="formBtnLine">
                <button class="btn" type="submit">申请订购</button>
              </div>
            </form>
          </div>

          <div class="backView" v-show="submited">
            <template v-if="success">
              <img
                class="icon"
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/09/icon_success.png"
                width="60"
                height="60"
              />
              <h2 class="title">恭喜您，提交成功！</h2>
              <p>我们的销售人员会在1-3个工作日和您联系</p>
            </template>
            <template v-else>
              <img
                class="icon"
                src="https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/09/icon_fail.png"
                width="60"
                height="60"
              />
              <h2 class="title">Sorry，提交失败~</h2>
            </template>
            <div class="btnLine">
              <button class="btn" type="button" @click="hidePopup">知道了</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "cs01",
  data() {
    return {
      formData: {
        name: '',
        mobile: '',
        area: '',
        remark: '',
      },
      formDataCheck: {
        name: false,
        mobile: false,
        area: false,
      },
      popupShow: false,
      submited: false,
      success: false,
      initPlay: true,
    }
  },
  mounted() {
    const ua =navigator.userAgent.toLowerCase();

    if(ua.match(/MicroMessenger/i)) {
      this.initPlay = false;
    }

    window.addEventListener('scroll', this.autoPlay);
  },
  destroyed() {
    window.removeEventListener('scroll', this.autoPlay);
  },
  methods: {
    autoPlay() {
      const { innerHeight: winHeight } = window;
      const { video } = this.$refs;
      const { height, top } = video.getBoundingClientRect();

      if (top < winHeight - 100 && top + height > 50) {
        video.paused && video.play();
      } else {
        !video.paused && video.pause();
      }
    },
    videoPlay() {
      this.$refs.video.play();
      this.initPlay = true;
    },
    showPopup() {
      this.popupShow = true;
      this.submited = false;
    },
    hidePopup() {
      this.popupShow = false;
      this.submited = false;
    },
    submitPopup(e) {
      e.preventDefault();

      const { formData, formDataCheck } = this;
      let verify;

      Object.keys(formDataCheck).forEach((key) => {
        formDataCheck[key] = !formData[key];

        if (formDataCheck[key]) {
          verify = true;
        }
      });

      if (verify) return;

      this.axios({
        url: 'https://api.partner.juzhen.cn/api/api/preorder/save',
        method: "post",
        data: {
          count: 1,
          prodId: 83,
          ...formData,
        }
      }).then((data) => {
        this.submited = true;
        this.success = data.code === 200;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  background-color: #f0f0f0;
}

.popup {
  position: relative;
  z-index: 999;
}

.hide {
  display: none !important;
}
.mShow {
  display: none !important;
}

.popupMasker,
.popupContent {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.popupMasker {
  background-color: rgba(0, 0, 0, .7);
  pointer-events: auto;
}

.popupContent {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.bookView,
.backView {
  position: relative;
  margin: 36px;
  padding: 16px;
  background-color: #F7F8F9;
  border-radius: 16px;
}

.bookView {
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    transition: transform .3s;
    cursor: pointer;

    &:hover {
      transform: rotate(180deg);
    }
    
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      width: 100%;
      height: 2px;
      content: '';
      transform: rotate(45deg);
      background-color: #979797;
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  .tips {
    margin-bottom: 20px;
    padding: 0 36px 0 16px;
    font-size: 14px;
    color: #949B9C;
    line-height: 16px;

    .title {
      display: block;
      margin-bottom: 8px;
      font-size: 16px;
      color: #323334;
      line-height: 36px;
    }
  }
}

.backView {
  padding: 24px 16px;
  font-size: 14px;
  color: #949B9C;
  text-align: center;
  line-height: 16px;

  .title {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #323334;
  }

  .btnLine {
    margin: 40px auto 0;
    width: 50%;

    .btn {
      display: block;
      width: 100%;
      height: 32px;
      padding: 0;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 32px;
      border-radius: 28px;
      background: #e83e0b;
      border: none;
      outline: none;
    }
  }
}

.formList {
  margin: 12px 0;
  padding: 10px 16px;
  background-color: #fff;
  border-radius: 8px;

  .item {
    display: flex;
    flex-wrap: wrap;
    line-height: 40px;
    border-bottom: 1px solid #D8D8D8;

    label {
      width: 80px;
      font-size: 14px;
      color: #323334;
      font-weight: bold;
    }

    .error {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: #f00;
    }
  }

  .itemBlock {
    display: block;
    border-bottom: none;
  }

  .input {
    flex: 1;
    padding: 4px 0;

    input {
      display: block;
      padding: 4px 0;
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      border: none;
      outline: none;
    }
  }

  textarea {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    border: none;
    outline: none;
    resize: none;
  }
}

.formBtnLine {
  margin: 16px;

  .btn {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 40px;
    border-radius: 28px;
    background: #e83e0b;
    border: none;
    outline: none;
  }
}

.layout01 {
  margin: 0 auto;
  max-width: 1920px;
}

.banner {
  margin: 0 auto;
  max-width: 1920px;
  padding: 235px 0 40px;
  text-align: center;
  font-size: 25px;
  line-height: 51px;
  background-color: #f0f0f0;

  h2 {
    font-size: 80px;
    font-weight: normal;
    line-height: 100px;
    font-family: PingFangSC-Ultralight;
  }

  .slogan {
    span {
      margin: 0 20px;
    }
  }

  .btnBook {
    display: inline-block;
    margin: 40px auto 30px;
    vertical-align: top;
    padding: 0 60px;
    line-height: 60px;
    font-size: 34px;
    color: #fff;
    border-radius: 60px;
    background-image: linear-gradient(#f34600 0%, #ef2e00 100%);
    cursor: pointer;
  }
}

.banner, .box {
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}

.subHeader {
  height: 76px;

  img {
    vertical-align: top;
  }
}
.subHeaderWrap {
  display: flex;
  padding: 20px 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  z-index: 99;
}

.subNav {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 36px;

  li {
    a {
      display: inline-block;
      vertical-align: top;
      padding: 0 16px;
      color: #222226;
    }

    &.current {
      a {
        color: #e83e0b;
      }
    }

    &::before {
      content: "|";
    }

    &:first-child::before {
      display: none;
    }
  }

  .btnBook {
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
    padding: 0 22px;
    color: #fff;
    background-color: #e83e0b;
    border-radius: 40px;
    cursor: pointer;
  }
}

.row {
  display: flex;
  justify-content: center;

  .box {
    flex: 1;
    width: 50%;
  }
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: none;
  overflow: hidden;

  .summary {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    z-index: 1;

    h3 {
      font-size: 80px;
      color: #fff;
      line-height: 100px;
    }

    h4 {
      font-size: 30px;
      line-height: 50px;
    }
  }
}

.strongBox, .fashionBox {
  padding-top: 6.77%;
  padding-bottom: 3.125%;
  background-image: linear-gradient(0, #373d3e 24%, #1c1e1f 27%, #292d2e 100%);
  /* background: #292d2e url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/bg_strong.jpg) repeat-x 50% 100%; */
  background-size: contain;

  .summary {
    margin-bottom: 115px;
    font-size: 30px;
    line-height: 50px;
  }
}

.fashionBox {
  background-color: #f4f4f4;
  background-image: linear-gradient(0, #dfdede 24%, #c8c7c6 27%, #f4f4f4 100%);
  /* background-image: url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/bg_fashion.jpg); */
}

.fashionBox, .smallBox, .bigBox {
  .summary {
    &, h3 {
      color: #222226;
    }
  }
}

.bigBox {
  padding-bottom: 5.729%;
  background: #e3eae5 url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/bg_big.jpg) no-repeat 50% 100%;
  background-size: contain;

  .summary {
    position: static;
    padding: 120px 0 55px;

    img {
      width: 50%;
    }
  }
}

.smallBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b4d1cf;

  .summary {
    position: absolute;
    top: 170px;
    left: 50%;
    display: flex;
    margin-left: -220px;
    flex-direction: column;
    justify-content: center;
    width: 440px;
    height: 295px;
    overflow: hidden;

    &::before {
      position: absolute;
      top: -40px;
      left: -40px;
      right: -40px;
      bottom: -40px;
      filter: blur(40px);
      content: "";
      background: url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/small.jpg) no-repeat 50% -170px;
      background-size: auto 100vh;
      z-index: -2;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(219, 232, 232, 0.4);
      z-index: -1;
    }
  }
}

.spaceRow {
  padding-bottom: 50px;
}
.spaceRow .box,
.batteryRow .item {
  margin: 0 25px;
  max-width: 790px;
}
.spaceBox, .loadingBox {
  padding: 90px 0 50px;

  .summary {
    margin-bottom: 30px;
    font-size: 20px;
    color: #222226;

    h3 {
      font-size: 40px;
      line-height: 64px;
      color: #222226;
    }
  }
}

.spaceBox {
  background-color: rgba(0, 232, 168, 0.9);
}
.loadingBox {
  background-color: rgba(34, 34, 38, 0.9);
}

.loadingBox {
  .summary {
    &, h3 {
      color: #00e8a8;
    }
  }
}

.safeBox {
  position: relative;
  padding: 160px 25px 0;
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  background-color: #171718;
  background-image: radial-gradient(100% 50% at 70% 0%, rgba(255,255,255,.5) 0%, rgba(255,255,255,0.00) 60%);
  background-repeat: no-repeat;
  z-index: 5;

  h2, h3 {
    margin-bottom: 10px;
    font-size: 32px;
    color: #fff;
    line-height: 50px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
.safeWrap {
  margin: 0 auto;
  max-width: 1630px;
}

.safe_1 {
  display: flex;
  width: 100%;

  .summary {
    margin-right: 100px;
    padding: 20px 0;
    width: 510px;
  
    h2 {
      font-size: 80px;
      line-height: 100px;
    }
  }

  .picWrap {
    flex: 1 0 0;
  }
}

.safeOther {
  display: flex;
  position: relative;
  padding: 110px 0 180px;
  top: 140px;
  margin-top: -140px;
  justify-content: space-between;
  background-color: #171718;
} 

.safe_2 {
  display: flex;
  width: 35.6%;
  margin-left: -20px;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;

  .summary {
    position: relative;
    padding-top: 150px;

    .icon {
      position: absolute;
      top: 76px;
      left: 50%;
      width: 53px;
      margin-left: -26px;
    }
  }
}

.safe_3 {
  width: 31.3%;

  .summary {
    position: relative;

    h3 {
      padding-left: 50px;
    }

    .icon {
      position: absolute;
      top: -16px;
      left: -16px;
      width: 53px;
    }
  }
}

.safe_4 {
  margin-right: 60px;
  width: 280px;
  text-align: center;

  .summary {
    h3 {
      font-size: 46px;
    }

    .icon {
      display: block;
      margin: 26px auto 12px;
    }
  }

  .picWrap {
    position: absolute;
    bottom: -21px;
    right: 0;
    width: 60%;
  }
}

.batteryBox {
  position: relative;
  padding-top: 274px;
  padding-bottom: 130px;
  background-color: #171718;
  background-image: radial-gradient(100% 80% at 30% 0%, rgba(255,255,255,.5) 0%, rgba(255,255,255,0.00) 60%);
  background-repeat: no-repeat;
  z-index: 3;

  .summary {
    margin-bottom: 46px;
    font-size: 32px;
    line-height: 48px;

    h3 {
      font-size: 60px;
      line-height: 80px;
    }
  }
}

.batteryRow {
  display: flex;

  .item {
    flex: 1;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    text-align: center;

    .video {
      display: block;
      width: 100%;
      height: auto;
    }

    .txt {
      display: flex;
      flex-direction: column;
      height: 215px;
      justify-content: center;
      background-color: #29292a;

      .icon {
        margin-bottom: 16px;
      }
    }
  }
}

.panelBox {
  padding: 7% 0 1.5625%;
  background: #e83e0b url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/bg_panel.jpg) no-repeat 50% 100%;
  background-size: contain;

  .summary {
    margin-bottom: 65px;
    font-size: 24px;

    h3 {
      font-size: 60px;
    }
  
    .p1 {
      margin-top: 20px;
    }
  
    span {
      padding: 0 12px;
    }
  }
}

.detailBox, .qualificationBox {
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  color: #010101;

  h3 {
    font-size: 60px;
    line-height: 80px;
  }
}

.detailBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 65px;
  background-color: #fff;

  .summary {
    margin-top: -330px;
    color: #fff;

    h3 {
      color: #fff;
    }
  }

  .list {
    display: flex;
    margin-top: 70px;
    padding: 35px 25px;
    justify-content: center;
    background-color: #fff;

    li {
      flex: 1;
      margin: 0 25px;
      max-width: 510px;
      font-size: 20px;
      line-height: 26px;
      font-weight: normal;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      h4 {
        margin: 35px 0 10px;
        font-size: 32px;
        line-height: 40px;
        color: #222226;
      }
    }
  }
}
.designBanner {
  display: block;
  width: 100%;
  height: auto;
}

.qualificationBox {
  padding: 115px 0 100px;
  background-color: #e3ddd6;

  .list {
    display: flex;
    margin-top: 80px;
    padding: 0 50px;
    justify-content: space-around;

    li {
      width: 320px;
      height: 196px;
      font-size: 30px;
      line-height: 37px;
      background: url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/25/bg_corolla.png) no-repeat 50%;
      background-size: contain;

      img {
        display: block;
        margin: 0 auto 10px;
      }

      strong {
        display: block;
      }
    }
  }
}

.paramsBox {
  padding: 120px 80px;
  color: #fff;
  background-color: #e83e0b;

  .title {
    // float: right;
    display: flex;
    margin: 0 auto 50px;
    // width: 52%;
    max-width: 900px;
    font-size: 48px;
    line-height: 1;
    color: #fff;
    align-items: flex-end;
    font-style: italic;

    .paramsLogo {
      width: 214px;
      height: 70px;
    }
  }

  .con {
    margin: 0 auto;
    max-width: 900px;
    // display: flex;
    // justify-content: space-between;
    // clear: both;

    .summary {
      img.car {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}

.params {
  table {
    font-size: 16px;
    line-height: 24px;

    .tit {
      width: 220px;
    }

    th {
      padding: 6px 4px;
    }

    td {
      padding: 4px;
      vertical-align: top;
    }

    thead, tbody, tfoot {
      border-top: 1px solid #fff;
    }
  }

  .first td {
    padding-top: 20px;
  }

  .last td {
    padding-bottom: 20px;
  }

  .tip {
    padding: 4px 0;
    line-height: 20px;
  }
}

.overview {
  display: flex;
  padding: 24px 16px;
  // margin-bottom: 36px;
  justify-content: space-around;

  .gap {
    width: 1px;
    background-color: #fff;
  }

  .item {
    display: flex;
    padding: 0 8px;
    align-items: flex-start;
    line-height: 1;

    .con {
      display: flex;
      margin-left: 12px;
      flex-direction: column-reverse;

      .num {
        display: block;
        margin-bottom: 4px;

        strong {
          font-weight: normal;
          font-size: 42px;
        }
      }
    }
  }
}

.bannerEnd {
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .summary {
    padding: 120px 0;
    color: #e83e0b;
    font-size: 60px;
    line-height: 80px;
    text-align: center;

    h3 {
      font-size: 45px;
      color: #e83e0b;
      font-weight: normal;
      line-height: 63px;
    }
  }

  .btnBook {
    display: inline-block;
    margin: 40px auto 0;
    vertical-align: top;
    padding: 0 60px;
    line-height: 60px;
    font-size: 34px;
    color: #fff;
    border-radius: 60px;
    background-image: linear-gradient(#f34600 0%, #ef2e00 100%);
    cursor: pointer;
  }
}

@media only screen and (max-width: 500px) {
  strong {
    font-weight: bold;
  }
  .row {
    display: block;

    .box {
      width: 100%;
    }
  }

  .mShow, .mBlock {
    display: block !important;
  }
  .mHide {
    display: none !important;
  }

  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 90px 0 0;
    font-size: 12px;

    h2 {
      font-size: 35px;
      line-height: 45px;
      font-weight: normal;
      font-family: PingFang SC;

      strong {
        display: block;
        font-size: 54px;
        font-weight: bold;
        line-height: 64px;
      }
    }

    .slogan span {
      margin: 0 8px;
    }
  
    .btnBook {
      margin: 12px auto 48px;
      padding: 0 36px;
      font-size: 20px;
      line-height: 36px;
      border-radius: 36px;
      cursor: pointer;
    }
  }

  .box {
    min-height: 100vh;
    background-size: cover !important;

    .summary {
      padding: 0 24px;
      font-size: 12px;
      line-height: 16px;

      h3 {
        margin-bottom: 8px;
        font-size: 50px;
        line-height: 58px;
      }
  
      h4 {
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .strongBox {
    padding: 106px 0 36px;
    /* background-image: url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/26/bg_strong.jpg); */
  }
  .strongBox, .fashionBox {
    .summary {
      margin-bottom: 80px;
      font-size: 20px;
      line-height: 30px;
    }
  }
  
  .fashionBox {
    padding: 106px 0 86px;
    /* background-image: url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/26/bg_fashion.jpg); */

    .summary {
      margin-bottom: 106px;
    }
  }
  
  .bigBox {
    padding: 106px 0 50px;
    background-image: linear-gradient(0, #e7f0ea 24%, #d2dbd5 27%, #dde5e0 100%);
    /* background-image: url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/26/bg_big.jpg); */
  
    .summary {
      margin-bottom: 55px;
    }
  
    img {
      width: 100%;
    }
  }
  
  .smallBox {
    .summary {
      position: absolute;
      margin-left: -120px;
      top: 95px;
      width: 240px;
      height: 160px;

      &::before {
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        filter: blur(15px);
        background: url(https://juzhen-store.oss-cn-beijing.aliyuncs.com/2021/02/07/small.jpg) no-repeat -67px -85px;
        background-size: 375px auto;
        z-index: -2;
      }
    }

    img {
      width: auto;
      max-width: none;
      height: 100vh;
    }
  }

  .spaceRow {
    padding-bottom: 0;

    .box {
      margin: 0;
      max-width: 100%;
      min-height: auto;
    }
  }

  .spaceBox, .loadingBox {
    padding: 90px 0 12px;
    background-color: #00cf96;

    .summary {
      margin-bottom: 28px;
      color: #222226;
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;
    }
  }

  .spaceBox {
    .summary {
      h3 {
        margin-bottom: 4px;
        font-size: 30px;
        line-height: 50px;
        font-weight: normal;
        color: #222226;

        strong {
          display: block;
          font-size: 50px;
          line-height: 68px;
        }
      }
    }
  }

  .loadingBox {
    padding: 0 0 75px;

    .summary {
      margin-bottom: 12px;

      h3 {
        margin-bottom: 4px;
        line-height: 50px;

        strong {
          font-size: 30px;
          font-weight: normal;
          color: #222226;
        }
      }
    }
  }

  .safeBox {
    padding: 120px 0 0;
    background-image: radial-gradient(100% 30% at 100% 0%, rgba(255,255,255,.5) 0%, rgba(255,255,255,0.00) 80%);

    h2, h3 {
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }
  }

  .safe_1, .safe_3 {
    .summary {
      margin: 0 0 42px;
      padding: 0 24px;
      font-size: 12px;
      line-height: 18px;
      width: 100%;
      text-align: justify;
    }
  }

  .safe_1 {
    display: block;
    .summary {
      h2 {
        font-size: 50px;
        line-height: 66px;
      }
    }
  }

  
  .safeOther {
    display: block;
    padding: 0;
    top: 0;
    margin-top: 0;
  } 
  
  .safe_2 {
    margin: 56px 0 0;
    width: auto;
    flex-direction: column;

    .summary {
      position: relative;
      padding-top: 0;
      font-size: 18px;
      line-height: 1.2;

      h3 {
        font-size: 32px;
      }

      .icon {
        position: static;
        margin: 20px auto 36px;
        width: 40px;
      }
    }
  }

  .safe_3 {
    margin-top: 30px;
    width: auto;

    .summary {
      margin-bottom: 36px;

      h3 {
        padding: 0;
      }

      .icon {
        position: static;
        display: block;
        margin: 10px auto 0;
        width: 40px;
      }
    }
  }

  .safe_4 {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 0;
    width: auto;
    text-align: center;

    .summary {
      margin-top: 16px;
      font-size: 12px;
      line-height: 18px;

      h3 {
        font-size: 25px;
      }

      .icon {
        display: block;
        margin: 16px auto 12px;
      }
    }

    .picWrap {
      position: static;
      width: 100%;
    }
  }

  .batteryBox {
    padding: 86px 0 0;
    background: #171718;

    .summary {
      margin-bottom: 42px;
      font-size: 16px;
      line-height: 20px;

      h3 {
        font-size: 50px;
        line-height: 56px;
      }
    }
  }

  .batteryRow {
    display: block;
    width: 100%;

    .item {
      position: relative;
      margin: 0;
      padding-top: 67.2%;
      font-size: 12px;
      line-height: 18px;

      .poster {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
      }
  
      .video {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: auto;
        z-index: 1;
      }

      .txt {
        display: flex;
        flex-direction: column;
        padding: 40px 0;
        height: auto;
        justify-content: center;
        background-color: #171718;

        .icon {
          margin-bottom: 8px;
          width: 52px;
        }
        
        .icon2 {
          width: 25px;
        }
      }
    }
  }
  
  .chassisBox {
    margin-top: -64px !important;
    background: none;

    .summary {
      padding: 0;
      color: #fff;

      h3 {
        margin-bottom: 20px;
        font-size: 25px;
        line-height: 35px;
        color: #fff;
      }

      .p1 {
        margin-bottom: 12px;
      }
    }

    .icon {
      width: 32px;
    }

    .pic {
      margin-top: -72px;
    }
  }

  .panelBox {
    padding: 110px 0 46px;
    background: #e83e0b;

    .summary {
      margin-bottom: 40px;

      h3 {
        margin-bottom: 20px;
        font-size: 25px;
        line-height: 29px;

        .mBlock {
          font-size: 40px;
          font-weight: normal;
          line-height: 46px;
        }
      }
  
      .p1 {
        margin-top: 12px;
      }
  
      .icon {
        width: 50px;
      }
  
      span {
        padding: 0 4px;
      }
    }
  }
  
  .detailBox, .qualificationBox {
    font-size: 14px;
    line-height: 18px;

    h3 {
      font-size: 30px;
      line-height: 50px;
    }
  }

  .detailBox {
    padding: 0 0 40px;
    background-color: #f0f0f0;

    .summary {
      position: relative;
      margin-top: 0;
      top: -38px;
      padding: 5px 10px 0;
      font-size: 15px;
      line-height: 17px;
      color: #010101;
      background-color: #f0f0f0;

      h3 {
        color: #010101;
      }
    }

    .list {
      margin: 0;
      width: 100%;
      flex-direction: column;
      padding: 0 12px;
      text-align: left;
      background: none;

      li {
        display: flex;
        margin: 0 0 20px;
        justify-content: center;
        align-items: flex-end;
        max-width: 100%;
        font-size: 12px;
        line-height: 16px;
        order: 1;

        img {
          width: 140px;
          order: 2;
        }

        .con {
          flex: 1;
          order: 2;
          margin-left: 16px;
          padding: 12px 0;
          white-space: nowrap;
        }
  
        h4 {
          margin: 0 0 4px;
          font-size: 16px;
          line-height: 28px;
          color: #222226;
        }
  
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          .con {
            margin: 0;
            order: 1;
          }
        }
      }
    }
  }
  
  .qualificationBox {
    padding: 60px 0 40px;
    background-color: #e3ddd6;
  
    .list {
      margin-top: 32px;
      padding: 0 20px;
      flex-wrap: wrap;

      li {
        margin-bottom: 10px;
        width: 50%;
        padding: 5px 24px;
        height: auto;
        font-size: 12px;
        line-height: 16px;
        background-size: 128px auto;

        img {
          margin: 0 auto 4px;
          width: 30px;
          height: auto;
        }
      }
    }
  }
  
  .paramsBox {
    padding: 80px 16px 0;

    .title {
      float: none;
      display: flex;
      margin-bottom: 24px;
      font-size: 22px;
      line-height: 1;

      .paramsLogo {
        width: 96px;
        height: 31px;
      }
    }

    .con {
      display: block;
      width: 100%;
      overflow: hidden;

      .summary {
        margin-left: -6px;
        min-width: auto;
      }

      .params {
        margin: 0;
        width: 120%;
        transform: scale(0.83);
        transform-origin: 0 0;

        .overview {
          display: flex;

          .iconElectricity {
            width: 20px;
            height: auto;
          }
          
          .iconMileage {
            width: 22px;
            height: auto;
          }
          
          .iconCharging {
            width: 20px;
            height: auto;
          }
        }
      }
    }
  }

  .params {
    table {
      font-size: 12px;
      line-height: 18px;

      .tit {
        width: 150px;
      }

      th {
        padding: 3px 2px;
      }
      
      td {
        padding: 2px;
      }

      thead, tbody, tfoot {
        border-top: 1px solid #fff;
      }
    }

    .first td {
      padding-top: 10px;
    }

    .last td {
      padding-bottom: 10px;
    }

    .tip {
      padding: 0;
      line-height: 18px;
    }
  }

  .overview {
    padding: 12px 0 18px;
    margin-bottom: 0;

    .gap {
      display: none;
    }

    .item {
      padding: 0;

      .con {
        margin-left: 6px;

        .num {
          margin-bottom: 4px;

          strong {
            font-size: 22px;
          }
        }
      }
    }
  }

  .bannerEnd {
    background-color: #f0f0f0;

    .summary {
      padding: 56px 0 36px;
      font-size: 26px;
      line-height: 36px;

      h3 {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .btnBook {
      margin: 20px auto 0;
      padding: 0 36px;
      font-size: 20px;
      line-height: 36px;
      border-radius: 36px;
    }
  }
}
</style>